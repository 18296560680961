import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'
import { InfoTooltip } from 'shared/InfoTooltip/InfoTooltip'

type TableHeaderCellProps<T> = {
  children: React.ReactNode
  /** If provided, `HeaderCell` will display `(i)` icon with corresponding tooltip text */
  tooltipText?: string
  /** Object field displayed in column under the cell */
  sortableKey?: keyof T
  /** Object field, that currently used to sort table rows */
  currentSortableKey?: keyof T | null
  /** Direction of sorting */
  sortableDirection?: 'asc' | 'desc'
  /**
   * Activated if user clicked on `HeaderCell` & `sortableKey`, `currentSortableKey` and
   * `sortableDirection` provider. Otherwise will be ignored
   * */
  onSort?: (key: keyof T) => void
} & AllHTMLAttributes<HTMLTableCellElement>
/**
 * Represents header item in row. Will display arrows if `onSort` is provided.
 */
function TableHeaderCell<T>({
  children,
  tooltipText,
  onSort,
  sortableDirection,
  sortableKey,
  currentSortableKey,
  ...restProps
}: Readonly<TableHeaderCellProps<T>>) {
  const handleClick = () => {
    if (!sortableKey) return
    if (!sortableDirection) return
    if (!onSort) return
    onSort(sortableKey)
  }

  return (
    <th className="TableHeaderCell" onClick={() => handleClick()} {...restProps}>
      <div className={classNames('TableHeaderCell__content', onSort && 'hoverable')}>
        {children}
        {onSort && (
          <SortingArrows
            direction={sortableKey === currentSortableKey ? sortableDirection : undefined}
          />
        )}
        {tooltipText && <InfoTooltip>{tooltipText}</InfoTooltip>}
      </div>
    </th>
  )
}

/** Use it when you need just empty header cell (e.g. menu column) */
function TableEmptyHeaderCell() {
  return <th className="TableEmptyHeaderCell TableHeaderCell">.</th>
}

type SortingArrowsProps = { direction?: 'asc' | 'desc' } & AllHTMLAttributes<SVGElement>
function SortingArrows({ direction, ...rest }: Readonly<SortingArrowsProps>) {
  return (
    <svg
      className={classNames({
        SortingArrows: true,
        'SortingArrows--asc': direction === 'asc',
        'SortingArrows--desc': direction === 'desc',
        'SortingArrows--active': direction !== undefined,
      })}
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="SortingArrows__arrow SortingArrows__arrow--asc"
        d="M8.21339 13.2156C8.10672 13.3578 7.89339 13.3578 7.78672 13.2156L5.32005 9.92667C5.18821 9.75087 5.31364 9.5 5.53339 9.5H10.4667C10.6865 9.5 10.8119 9.75087 10.6801 9.92667L8.21339 13.2156Z"
      />
      <path
        className="SortingArrows__arrow SortingArrows__arrow--desc"
        d="M8.21339 2.78465C8.10672 2.64242 7.89339 2.64242 7.78672 2.78465L5.32005 6.07353C5.18821 6.24933 5.31364 6.5002 5.53339 6.5002H10.4667C10.6865 6.5002 10.8119 6.24933 10.6801 6.07353L8.21339 2.78465Z"
      />
    </svg>
  )
}

TableHeaderCell.Empty = TableEmptyHeaderCell
export default TableHeaderCell

import {
  flip,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react'

type FloatingProps = {
  trigger: React.ReactNode
  content: React.ReactNode
  opened: boolean
  minWidth?: number
  setOpened: (open: boolean) => void
  closeOnClickInside?: boolean
  getScrollToElementCb?: (floatingContainer: HTMLElement) => HTMLElement | null
  activationMethod?: 'click' | 'hover'
}
export function Floating({
  trigger,
  content,
  opened,
  setOpened,
  minWidth = 100,
  activationMethod = 'click',
}: Readonly<FloatingProps>) {
  const { context, refs, floatingStyles } = useFloating({
    middleware: [flip()],
    placement: 'bottom-start',
    open: opened,
    onOpenChange: setOpened,
    strategy: 'fixed',
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])
  const { styles } = useTransitionStyles(context, {
    open: {
      marginTop: 1,
      opacity: 1,
    },
    initial: {
      marginTop: -3,
      opacity: 0,
      transitionTimingFunction: 'ease-out',
    },
    duration: 100,
  })

  return (
    <div className="Floating">
      <div className="Floating__trigger" ref={refs.setReference} {...getReferenceProps()}>
        {trigger}
      </div>
      {opened && (
        <div
          className={'Floating__content ' + (opened ? 'Floating__content--elevated' : '')}
          ref={refs.setFloating}
          style={{ ...floatingStyles, ...styles, minWidth }}
          {...getFloatingProps()}
        >
          {content}
        </div>
      )}
    </div>
  )
}

import imgLogo from 'assets/trading-journal-logo.svg'
import './PageLayout.scss'

type PageLayoutProps = { children: React.ReactNode; className?: string }
function PageLayout({ children, className }: Readonly<PageLayoutProps>) {
  return <div className={'PageLayout ' + className}>{children}</div>
}

type PageLayoutHeaderProps = { children: React.ReactNode; className?: string }
function PageLayoutHeader({ children, className }: Readonly<PageLayoutHeaderProps>) {
  return (
    <div className={'PageLayoutHeader ' + className}>
      <div className="PageLayoutHeader__content">{children}</div>
    </div>
  )
}

type PageLayoutContentProps = { children: React.ReactNode; className?: string }
function PageLayoutContent({ children, className }: Readonly<PageLayoutContentProps>) {
  return (
    <div className={'PageLayoutContent ' + className}>
      <div className="PageLayoutContent__content">{children}</div>
    </div>
  )
}

type PageLayoutFooterProps = { children: React.ReactNode; className?: string }
function PageLayoutFooter({ children, className }: Readonly<PageLayoutFooterProps>) {
  return (
    <div className={'PageLayoutFooter ' + className}>
      <div className="PageLayoutFooter__content">{children}</div>
    </div>
  )
}

function PageLayoutHeaderDefault() {
  const signOut = async () => {
    localStorage.removeItem('TJ_ACCESS_TOKEN')
    window.location.reload()
  }

  return (
    <PageLayoutHeader className="PageLayoutHeaderDefault">
      <div className="PageLayoutHeaderDefault__logo">
        <img src={imgLogo} alt="Bookmap" />
        <h1>Trading journal</h1>
      </div>
      <nav className="PageLayoutHeaderDefault__content">
        <a
          href="https://bookmap.com/knowledgebase/docs/Addons-Trading-Journal"
          target="_blank"
          rel="noreferrer"
        >
          <button className="text-button">Quick guide</button>
        </a>
        <a href="https://bookmap.com/support/" target="_blank" rel="noreferrer">
          <button className="text-button">Contact support team</button>
        </a>
        <a href="https://forms.gle/d33URJfpN3w1yDi47" target="_blank" rel="noreferrer">
          <button className="accent-button">Share your feedback</button>
        </a>
        <button className="logout-button" onClick={() => signOut()}>
          Logout
        </button>
      </nav>
    </PageLayoutHeader>
  )
}

function PageLayoutFooterDefault() {
  return (
    <PageLayoutFooter className="PageLayoutFooterDefault">
      Have something to say? Email us at{' '}
      <a href="mailto:trading.journal@bookmap.com" target="_blank" rel="noreferrer">
        trading.journal@bookmap.com
      </a>
    </PageLayoutFooter>
  )
}

PageLayout.CustomHeader = PageLayoutHeader
PageLayout.CustomFooter = PageLayoutFooter

PageLayout.Header = PageLayoutHeaderDefault
PageLayout.Content = PageLayoutContent
PageLayout.Footer = PageLayoutFooterDefault
export default PageLayout

import { forwardRef, useEffect, useMemo } from 'react'
import { compareTime } from '../utils'

type DateInputTimePickerProps = {
  value: Date
  pickerDates: Date[]
  onChange: (value: Date) => void
  compactTimeFormatter: Intl.DateTimeFormat
}
export const DateInputTimePicker = forwardRef<HTMLDivElement, DateInputTimePickerProps>(
  ({ value, pickerDates, onChange, compactTimeFormatter }, elRef) => {
    const selectedTimeIndex = useMemo(() => {
      return pickerDates.findIndex((d) => compareTime(d, value))
    }, [pickerDates, value])

    useEffect(() => {
      pickerDates[selectedTimeIndex] && onChange(pickerDates[selectedTimeIndex])
    }, [pickerDates, onChange, selectedTimeIndex])

    return (
      <div className="DateInput__content__time-picker" ref={elRef}>
        {pickerDates.map((d, index) => {
          return (
            <button
              key={d.getTime()}
              className={selectedTimeIndex === index ? 'menu-button selected' : 'menu-button'}
              onClick={() => onChange(d)}
            >
              {compactTimeFormatter.format(d)}
            </button>
          )
        })}
      </div>
    )
  }
)

import './ModalHeader.scss'

type ModalHeaderProps = { label: string; onClose: () => void }
function ModalHeader({ label, onClose }: Readonly<ModalHeaderProps>) {
  return (
    <div className="ModalHeader">
      <div className="label text-big-bold">{label}</div>
      <button className="menu-button" onClick={() => onClose()}>
        X
      </button>
    </div>
  )
}

export default ModalHeader

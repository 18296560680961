import { LocalSortingSchema, useLocalSorting } from 'core/hooks/useLocalSorting'
import { JournalDetailedInfo } from 'core/types'
import { useMemo } from 'react'

export function useJournalSorting() {
  const schema: LocalSortingSchema<JournalDetailedInfo> = useMemo(() => {
    return {
      journalName: (a, b) => a.localeCompare(b),
      totalPnl: (a, b) => a - b,
      totalTrades: (a, b) => a - b,
      maxDrawdown: (a, b) => a - b,
    } satisfies LocalSortingSchema<JournalDetailedInfo>
  }, [])

  const { sortedItems, sortingKey, setItems, direction, sortItems } =
    useLocalSorting<JournalDetailedInfo>('journalName', schema)

  return { sortedItems, sortingKey, setItems, direction, sortItems }
}

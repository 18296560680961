import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import PageLayout from 'shared/PageLayout'
import TextDate from 'shared/TextDate'
import Menu from 'shared/Menu'
import Table from 'shared/Table'
import Card from 'shared/Card'
import TextMoney from 'shared/TextMoney'
import { useJournalsApi } from 'core/contexts/journalsApiContext'
import { JournalDetailedInfo } from 'core/types'
import { useJournalSorting } from './useJournalSorting'

import img3Dot from 'assets/icons/dots-vertical.svg'
import imgEdit from 'assets/icons/edit.svg'
import imgDelete from 'assets/icons/trash.svg'
import './ManageJournalsPage.scss'
import { JOURNALS_TYPE_NAME_MAP } from 'core/journalsNameMap'
import { SimpleDropdown } from 'shared/Dropdown'
import { useGlobalTimezoneClock, useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'

export function ManageJournalsPage() {
  const { journalsDetailed, openDeleteDialog, openEditDialog } = useJournalsApi()
  const navigate = useNavigate()
  const { openAddDialog } = useJournalsApi()
  const { sortedItems, sortingKey, setItems, direction, sortItems } = useJournalSorting()

  const { availableTimeZonesNameOptions, setTimeZone, timeZone } = useGlobalTimezone()
  const timeFormatted = useGlobalTimezoneClock()

  useEffect(() => {
    if (!journalsDetailed.data) return setItems([])
    setItems(journalsDetailed.data.result)
  }, [journalsDetailed, setItems])

  return (
    <PageLayout className="ManageJournalsPage">
      <PageLayout.Header />
      <PageLayout.Content>
        <div className="heading">
          <h4>Journal manager</h4>

          <button onClick={() => navigate('/dashboard')}>&lt; Back to main page</button>
        </div>

        <Card className="ManageJournalsPage__content">
          <div className="ManageJournalsPage__content__heading">
            <div className="text-normal-bold">Your journals</div>

            <div className="buttons">
              <SimpleDropdown
                value={timeZone}
                onChange={setTimeZone}
                getVisualValue={(v) => `${timeFormatted} (UTC${v.timezoneOffsetName})`}
              >
                {availableTimeZonesNameOptions.map((tz) => (
                  <SimpleDropdown.Item value={tz} key={tz.timezoneOffsetName}>
                    <SimpleDropdown.ItemText
                      primary={
                        'UTC' +
                        tz.timezoneOffsetName +
                        (tz.abbreviations ? ` (${tz.abbreviations.join(', ')})` : '')
                      }
                      secondary={Array.from(tz.regions).join(', ')}
                    />
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>

              <button className="solid-button add-button" onClick={() => openAddDialog()}>
                + Add journal
              </button>
            </div>
          </div>
          <Table>
            <Table.Header>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="journalName"
                sortableDirection={direction}
                currentSortableKey={sortingKey}
                onSort={(key) => sortItems(key)}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="journalType"
                currentSortableKey={sortingKey}
                sortableDirection={direction}
                onSort={(key) => sortItems(key)}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="creationDate"
                sortableDirection={direction}
                currentSortableKey={sortingKey}
                onSort={(key) => sortItems(key)}
              >
                Creation date
              </Table.HeaderCell>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="totalTrades"
                sortableDirection={direction}
                currentSortableKey={sortingKey}
                onSort={(key) => sortItems(key)}
              >
                Trades
              </Table.HeaderCell>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="totalPnl"
                sortableDirection={direction}
                currentSortableKey={sortingKey}
                onSort={(key) => sortItems(key)}
              >
                P&L
              </Table.HeaderCell>
              <Table.HeaderCell<JournalDetailedInfo>
                sortableKey="maxDrawdown"
                sortableDirection={direction}
                currentSortableKey={sortingKey}
                onSort={(key) => sortItems(key)}
              >
                Max drawdown
              </Table.HeaderCell>
              <Table.HeaderCell.Empty />
            </Table.Header>
            <Table.Body>
              {sortedItems.map((journal) => (
                <Table.Row
                  key={journal.id}
                  onClick={() => navigate('/dashboard?journal_id=' + journal.id)}
                >
                  <Table.Cell>{journal.journalName}</Table.Cell>
                  <Table.Cell>
                    {JOURNALS_TYPE_NAME_MAP[journal.journalType] ?? journal.journalType}
                  </Table.Cell>
                  <Table.Cell>
                    {journal.creationDate ? (
                      <TextDate date={new Date(journal.creationDate)} />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>{journal.totalTrades}</Table.Cell>
                  <Table.Cell>
                    <TextMoney value={journal.totalPnl} colored />
                  </Table.Cell>
                  <Table.Cell>
                    <TextMoney value={journal.maxDrawdown} colored />
                  </Table.Cell>

                  <Table.Cell.Menu>
                    <Menu
                      trigger={
                        <button className="menu-button menu-trigger">
                          <img src={img3Dot} alt="Menu" />
                        </button>
                      }
                    >
                      <Menu.Item onClick={() => openEditDialog(journal)}>
                        <img src={imgEdit} alt="Edit" width={20} height={20} /> Edit
                      </Menu.Item>
                      {journal.typeEditable === true && (
                        <Menu.Item onClick={() => openDeleteDialog(journal)}>
                          <img src={imgDelete} alt="Delete" width={20} height={20} /> Remove
                        </Menu.Item>
                      )}
                    </Menu>
                  </Table.Cell.Menu>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}

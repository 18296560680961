import { LS_DASHBOARD_FILTERS } from 'core/constants'
import { JournalInfo } from 'core/types'
import { createContext, useEffect, useMemo, useState } from 'react'

export type FilterState = {
  dateFrom: Date
  dateTo: Date
  symbols: string[]
  journal: JournalInfo | null
}
export type FilterSerializedState = {
  dateFrom: string
  dateTo: string
  symbols: string[]
  journalId: string
}

type FilterContextData = {
  filters: FilterState
  setFilters: React.Dispatch<React.SetStateAction<FilterState>>
}
export const FilterContext = createContext<FilterContextData>({
  filters: {
    dateFrom: new Date(0),
    dateTo: new Date(0),
    symbols: [],
    journal: null,
  },
  setFilters: () => {},
})

export function FilterContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [filters, setFilters] = useState<FilterState>({
    dateFrom: new Date(0),
    dateTo: new Date(0),
    symbols: [],
    journal: null,
  })

  const serializedFilters = useMemo(() => {
    return {
      dateFrom: filters.dateFrom.toISOString(),
      dateTo: filters.dateTo.toISOString(),
      symbols: filters.symbols,
      journalId: filters.journal?.id ?? '',
    } satisfies FilterSerializedState
  }, [filters])

  useEffect(() => {
    if (
      new Date(serializedFilters.dateFrom).getTime() === 0 &&
      new Date(serializedFilters.dateTo).getTime() === 0
    )
      return
    localStorage.setItem(LS_DASHBOARD_FILTERS, JSON.stringify(serializedFilters))
  }, [serializedFilters])

  const providerValue = useMemo(() => {
    return {
      filters,
      setFilters,
    } satisfies FilterContextData
  }, [filters, setFilters])

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>
}

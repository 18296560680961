import { EMAIL_REGEXP, LICENSE_KEY_REGEXP } from 'core/regexp'
import { HttpError, appFetch } from 'core/utils'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { InfoTooltip } from 'shared/InfoTooltip/InfoTooltip'
import { API_URL } from 'core/constants'

type FormInputs = {
  email: string
  licenseKey: string
  password: string
  passwordConfirm: string
}

export function SignUpForm() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [userExist, setUserExist] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm<FormInputs>()

  const allFields = useWatch({ control })

  const sendRegisterRequest = async (data: FormInputs) => {
    setLoading(true)
    setUserExist(null)

    try {
      const resp = await appFetch(API_URL + '/api/dashboard/register', 'POST', {
        email: data.email,
        password: data.password,
        licenseKey: data.licenseKey,
      })

      if ('access_token' in resp) {
        localStorage.setItem('TJ_ACCESS_TOKEN', resp.access_token)
        setTimeout(() => navigate('/'))
      } else {
        console.error('No access token in response!')
        setTimeout(() => navigate('/login'))
      }
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 409) {
          setUserExist(allFields.email ?? 'Unknown email')
          setTimeout(() => {
            trigger()
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = handleSubmit((data: FormInputs) => sendRegisterRequest(data))

  const paste = async () => {
    const value = await navigator.clipboard.readText()
    setValue('licenseKey', value.trim())
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="form-control">
        <label htmlFor="email">Email</label>
        <input
          {...register('email', {
            required: 'Name is required',
            pattern: { value: EMAIL_REGEXP, message: 'Invalid email' },
            validate: (v) => v !== userExist || 'User already exists',
          })}
          type="text"
          id="email"
          placeholder="Enter email"
          autoComplete="false"
          className={classNames({ error: errors.email })}
        />
        {errors.email && <div className="error-text">{errors.email.message}</div>}
      </div>
      <div className="form-control">
        <label htmlFor="licenseKey">
          License key{' '}
          <InfoTooltip>
            The verification email from Bookmap Support after registration contains your license
            key. Also, you can find it in the user account on the Bookmap website or in the Bookmap
            desktop app (Help -&gt; About)
          </InfoTooltip>{' '}
        </label>
        <div className="input">
          <input
            id="licenseKey"
            type="text"
            placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX"
            {...register('licenseKey', {
              required: 'License key is required',
              minLength: { value: 34, message: 'License key must be 34 characters' },
              pattern: {
                value: LICENSE_KEY_REGEXP,
                message: 'Invalid license key',
              },
            })}
            className={classNames({ error: errors.licenseKey })}
          />
          <button type="button" className="solid-button" onClick={() => paste()}>
            Paste
          </button>
        </div>
        {errors.licenseKey && <div className="error-text">{errors.licenseKey.message}</div>}
      </div>
      <div className="form-control">
        <label htmlFor="password">Password</label>
        <input
          {...register('password', {
            required: 'Password is required',
            minLength: { value: 6, message: 'Password must be at least 6 characters' },
          })}
          id="password"
          type="password"
          autoComplete="new-password"
          placeholder="Enter password"
          className={classNames({ error: errors.password })}
        />
        {errors.password && <div className="error-text">{errors.password.message}</div>}
      </div>
      <div className="form-control">
        <label htmlFor="passwordConfirm">Confirm password</label>
        <input
          {...register('passwordConfirm', {
            required: 'Password confirmation is required',
            validate: (v) => v === allFields.password || 'Passwords do not match',
          })}
          id="passwordConfirm"
          type="password"
          autoComplete="new-password"
          placeholder="Confirm password"
          className={classNames({ error: errors.passwordConfirm })}
        />
        {errors.passwordConfirm && (
          <div className="error-text">{errors.passwordConfirm.message}</div>
        )}
      </div>

      {loading ? (
        <button className="accent-button" disabled>
          Loading...
        </button>
      ) : (
        <button className="accent-button" disabled={isSubmitted && !isValid}>
          Register
        </button>
      )}
    </form>
  )
}

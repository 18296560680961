import { TABLE_PAGE_SIZE } from 'core/constants'
import { useFilteredApi } from 'core/hooks/api/useApi'
import { PnlItem, TradesResponse, TradingStatistics } from 'core/types'
import { createContext, useContext, useMemo, useState } from 'react'
import { SWRResponse } from 'swr'
import { BareFetcher, PublicConfiguration } from 'swr/_internal'

type SWRResp<T> = SWRResponse<
  T,
  any,
  Partial<PublicConfiguration<T, any, BareFetcher<T>>> | undefined
>

const initialSwrData = {
  data: undefined,
  error: undefined,
  isValidating: false,
  mutate: () => Promise.resolve() as Promise<undefined>,
  isLoading: true,
}

type DashboardApiContextData = {
  pnl: SWRResp<{ result: { dataPoints: PnlItem[] } }>
  statistics: SWRResp<{ result: TradingStatistics }>
  trades: SWRResp<TradesResponse>
  mutateAll: () => Promise<void>
  paginationPage: number
  setPaginationPage: React.Dispatch<React.SetStateAction<number>>
}
export const DashboardApiContext = createContext<DashboardApiContextData>({
  pnl: initialSwrData,
  statistics: initialSwrData,
  trades: initialSwrData,
  mutateAll: () => {
    throw new Error('Not implemented')
  },
  paginationPage: 0,
  setPaginationPage: () => void 0,
})
export const useDashboardApi = () => useContext(DashboardApiContext)

type DashboardApiContextProviderProps = { children: React.ReactNode }
export function DashboardApiContextProvider({
  children,
}: Readonly<DashboardApiContextProviderProps>) {
  const pnl = useFilteredApi<{ result: { dataPoints: PnlItem[] } }>('/api/dashboard/pnl')
  const statistics = useFilteredApi<{ result: TradingStatistics }>('/api/dashboard/statistics')
  const [paginationPage, setPaginationPage] = useState<number>(0)
  const trades = useFilteredApi<TradesResponse>('/api/dashboard/trades', {
    page: paginationPage,
    pageSize: TABLE_PAGE_SIZE,
  })

  const providerValue: DashboardApiContextData = useMemo(
    () =>
      ({
        pnl,
        statistics,
        trades,
        paginationPage,
        setPaginationPage,
        mutateAll: async () => {
          await Promise.all([pnl.mutate(), statistics.mutate(), trades.mutate()])
        },
      } satisfies DashboardApiContextData),
    [pnl, statistics, trades, paginationPage, setPaginationPage]
  )

  return (
    <DashboardApiContext.Provider value={providerValue}>{children}</DashboardApiContext.Provider>
  )
}

type ConfirmDeleteDialogProps = {
  onClose: () => void
  onDelete: () => void
  loading: boolean
}
export function ConfirmDeleteDialog({
  onClose,
  onDelete,
  loading,
}: Readonly<ConfirmDeleteDialogProps>) {
  return (
    <div className="ConfirmDeleteDialog modal">
      <h4 className="modal-header">Delete journal</h4>

      <p>
        Once removed, this action cannot be undone. The user will lose access to the journal and any
        associated data.
      </p>

      <div className="modal-buttons">
        <button className="solid-button" disabled={loading} onClick={() => onClose()}>
          Cancel
        </button>
        <button className="accent-button error" disabled={loading} onClick={() => onDelete()}>
          {loading ? 'Loading...' : 'Remove'}
        </button>
      </div>
    </div>
  )
}

import { AllHTMLAttributes, useEffect, useMemo, useState } from 'react'
import './DateInput.scss'
import Calendar from 'react-calendar'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import Floating from 'shared/Floating'
import classNames from 'classnames'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { DateInputTrigger } from './components/DateInputTrigger'
import { DateInputTimePicker } from './components/DateInputTimePicker'
import { useTimePickerAutoscroll } from './useTimePickerAutoscroll'
import { getDatesToPick } from './utils'

type DateInputProps = Omit<AllHTMLAttributes<HTMLDivElement>, 'value' | 'onChange'> & {
  value?: Date
  onChange?: (value: Date) => void
}
export function DateInput({
  value = getInitialTime(),
  onChange = () => {},
  className,
  ...rest
}: Readonly<DateInputProps>) {
  const [open, setOpen] = useState(false)
  const [baseDate, setBaseDate] = useState<Date>(value || getInitialTime)
  const [timedDate, setTimedDate] = useState<Date>(value || getInitialTime)

  const { timeZone } = useGlobalTimezone()
  const timePickerDates = useMemo(() => {
    const newPickDates = getDatesToPick(timeZone.offsetMsRelative, baseDate)
    return newPickDates
  }, [timeZone, baseDate])

  const { timePickerRef } = useTimePickerAutoscroll(open)
  const { dateFormatter, getDateTimeFormatter } = useGlobalTimezone()

  useEffect(() => {
    setBaseDate(value)
    setTimedDate(value)
  }, [value])

  /** Date > 12:34 */
  const compactTimeFormatter = useMemo(
    () => getDateTimeFormatter({ hour: 'numeric', minute: 'numeric' }),
    [getDateTimeFormatter]
  )

  const apply = () => {
    onChange(timedDate)
    setOpen(false)
  }
  const today = () => setBaseDate(new Date())

  const updateDateValue = (value: Value) => {
    if (!(value instanceof Date)) return
    setBaseDate(value)
  }

  return (
    <div className={classNames('DateInput', className)} {...rest}>
      <Floating
        opened={open}
        setOpened={(opened) => setOpen(opened)}
        closeOnClickInside={false}
        trigger={
          <DateInputTrigger
            open={open}
            value={timedDate}
            dateFormatter={dateFormatter}
            compactTimeFormatter={compactTimeFormatter}
          />
        }
        content={
          <div className="DateInput__content">
            <div className="DateInput__content__pickers-wrapper">
              <Calendar
                className="DateInput__content__calendar"
                locale="en-GB"
                value={baseDate}
                onChange={updateDateValue}
                minDetail={'month'}
              />
              <DateInputTimePicker
                ref={timePickerRef}
                value={timedDate}
                pickerDates={timePickerDates}
                onChange={setTimedDate}
                compactTimeFormatter={compactTimeFormatter}
              />
            </div>
            <div className="DateInput__content__controls">
              <button className="text-button" onClick={() => today()}>
                Today
              </button>
              <button
                className="link-button"
                onClick={() => apply()}
                disabled={timedDate.getTime() === value.getTime()}
              >
                Apply
              </button>
            </div>
          </div>
        }
      />
    </div>
  )
}

function getInitialTime() {
  return new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000)
}

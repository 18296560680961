import { API_URL } from 'core/constants'
import { JournalDetailedInfo, JournalInfo } from 'core/types'
import { HttpError, appFetch } from 'core/utils'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { EditJournalForm, EditJournalFormData } from './EditJournalForm'
import { useJournalsApi } from 'core/contexts/journalsApiContext'

type AddJournalDialogProps = {
  onClose: () => void
  journal?: JournalInfo | JournalDetailedInfo | null
}
export function EditJournalDialog({ onClose, journal }: Readonly<AddJournalDialogProps>) {
  const [loading, setLoading] = useState(false)
  const [occupiedName, setOccupiedName] = useState<string | null>(null)
  const { updateJournals } = useJournalsApi()
  const useFormReturn = useForm<EditJournalFormData>()
  const {
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
    formState: { isValid },
  } = useFormReturn

  useEffect(() => {
    clearErrors()
    if (journal) {
      setValue('journalName', journal.journalName)
      setValue('journalType', journal.journalType)
    }
  }, [clearErrors, journal, setValue])

  const close = () => {
    clearErrors()

    onClose()
  }

  const onSubmit = handleSubmit(async (data) => {
    if (!isValid) return
    if (!journal) return
    if (data.journalName === journal.journalName && data.journalType === journal.journalType) {
      close()
      return
    }
    setLoading(true)
    try {
      await appFetch(API_URL + '/api/dashboard/journals/' + journal.id, 'PUT', {
        journalName: data.journalName,
        journalType: data.journalType,
      })
      updateJournals()
      close()
      setValue('journalName', '')
      setValue('journalType', '')
    } catch (error) {
      console.error(
        'Failed to edit journal\n',
        typeof error === 'object' && error && 'body' in error && error.body
      )
      if (error instanceof HttpError) {
        if (error.status === 400) {
          setOccupiedName(data.journalName)
        }
      }
      setTimeout(() => trigger())
    } finally {
      setLoading(false)
    }
  })

  return (
    <EditJournalForm
      loading={loading}
      occupiedName={occupiedName}
      useFormReturn={useFormReturn}
      onCancel={() => close()}
      onSubmit={onSubmit}
      actionText="Edit"
      disableTypeDropdown={(journal as JournalDetailedInfo)?.typeEditable === false}
    />
  )
}

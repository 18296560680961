import { LS_RESTORE_PATH } from 'core/constants'
import './App.scss'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { JournalsApiContextProvider } from 'core/contexts/journalsApiContext'
import { Notifications } from 'shared/Notifications'
import { GlobalTimezoneContextProvider } from 'core/contexts/GlobalTimezoneContext'

export function App() {
  const navigate = useNavigate()

  useEffect(() => {
    if (
      localStorage.getItem('TJ_ACCESS_TOKEN') === null &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/signup'
    ) {
      localStorage.setItem(LS_RESTORE_PATH, window.location.pathname + window.location.search)
      navigate('/login')
    }
  })

  return (
    <div className="App">
      <GlobalTimezoneContextProvider>
        <Notifications>
          <JournalsApiContextProvider>
            <Outlet />
          </JournalsApiContextProvider>
        </Notifications>
      </GlobalTimezoneContextProvider>
    </div>
  )
}

export default App

import imgCaretDown from 'assets/icons/caret-down.svg'
import classNames from 'classnames'
import { forwardRef } from 'react'

type DropdownTriggerProps = {
  opened: boolean
  placeholder: string
  value?: string
  onBlur?: () => void
}
export const DropdownTrigger = forwardRef<HTMLButtonElement, DropdownTriggerProps>(
  ({ opened, placeholder, value, onBlur }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className="DropdownTrigger text-normal-regular"
        onBlur={() => onBlur && onBlur()}
      >
        {value !== undefined ? (
          <div className="DropdownTrigger__content value">{value}</div>
        ) : (
          <div className="DropdownTrigger__content placeholder">{placeholder}</div>
        )}

        <img
          src={imgCaretDown}
          alt="Caret"
          className={classNames(
            'DropdownTrigger__arrow',
            opened && 'DropdownTrigger__arrow--flipped'
          )}
        />
      </button>
    )
  }
)

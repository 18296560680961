import { FC } from 'react'
import './Chart.scss'
import { Chart } from './Chart'
import { PnlItem } from 'core/types'
import { useDashboardApi } from '../../../DashboardApiContext'
import { useGlobalTimezone, useGlobalTimezoneClock } from 'core/contexts/GlobalTimezoneContext'

export type ChartItem = { time: number; value: number }

type Props = { label: string }
/** Chart wrapper with header & some minor handling of empty data */
export const ChartSection: FC<Props> = ({ label }) => {
  const {
    pnl: { data },
  } = useDashboardApi()

  const transformData = (items?: PnlItem[]): ChartItem[] => {
    if (!items) return []
    return items.map((item) => ({
      ...item,
      time: new Date(item.time).getTime(),
    }))
  }

  const timeFormatted = useGlobalTimezoneClock()
  const { timeZone } = useGlobalTimezone()

  return (
    <div className="Chart">
      <div className="Chart__label">
        <div>{label}</div>

        <abbr title='To change the timezone, go to Journal manager page by clicking on "Manage"'>
          <div>{timeFormatted + ' (UTC' + timeZone.timezoneOffsetName + ')'}</div>
        </abbr>
      </div>
      {data !== null ? (
        <div className="Chart__content">
          {data?.result.dataPoints.length === 0 ? (
            <div className="empty-state">No data</div>
          ) : (
            <Chart items={transformData(data?.result.dataPoints)} />
          )}
        </div>
      ) : (
        <div className="Chart__content_placeholder"></div>
      )}
    </div>
  )
}

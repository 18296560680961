import { JournalInfo, TradeItem } from 'core/types'
import { createContext, useMemo, useRef, useState } from 'react'
import AddEditNoteDialog from './dialogs/AddEditNoteDialog'
import TradeInfoDialog from './dialogs/TradeInfoDialog/TradeInfoDialog'
import { SelectJournalDialog } from './dialogs/SelectJournalDialog'
import { ConfirmDeleteTradeDialog } from './dialogs/ConfirmDeleteTradeDialog'
import { appFetch, HttpError } from 'core/utils'
import { API_URL } from 'core/constants'
import { useNotifications } from 'shared/Notifications'

type TradesTableSectionContextData = {
  selectedTrade: TradeItem | null
  setSelectedTrade: React.Dispatch<React.SetStateAction<TradeItem | null>>
  openAddNoteDialog: (item?: TradeItem) => void
  openTradeInfoDialog: (item?: TradeItem) => void
  openChoseJournalDialog: (hideJournalId?: string[]) => Promise<JournalInfo | undefined>
  openConfirmDeleteTradeDialog: (item: TradeItem) => void
  updateDashboard: () => void
  updateJournals: () => void
}
export const TradesTableSectionContext = createContext<TradesTableSectionContextData>({
  selectedTrade: null,
  setSelectedTrade: () => {},
  openAddNoteDialog: () => {},
  openTradeInfoDialog: () => {},
  openChoseJournalDialog: () => Promise.resolve(void 0),
  openConfirmDeleteTradeDialog: () => {},
  updateDashboard: () => {},
  updateJournals: () => {},
})

type TradesTableSectionContextProps = {
  children: React.ReactNode
  onUpdateDashboard: () => void
  onUpdateJournals: () => void
}
export function TradesTableSectionContextProvider({
  children,
  onUpdateDashboard,
  onUpdateJournals,
}: Readonly<TradesTableSectionContextProps>) {
  const [selectedTrade, setSelectedTrade] = useState<TradeItem | null>(null)
  const [hiddenJournalId, setHiddenJournalId] = useState<string[]>([])

  const addNoteDialogRef = useRef<HTMLDialogElement>(null)
  const confirmDeleteRef = useRef<HTMLDialogElement>(null)
  const selectJournalDialogRef = useRef<HTMLDialogElement>(null)
  const selectJournalDialogPromiseResolveFm = useRef<(journal?: JournalInfo) => void>(() => {})
  const tradeInfoDialogRef = useRef<HTMLDialogElement>(null)

  const { showNotification } = useNotifications()
  const [loading, setLoading] = useState(false)

  const providerValue = useMemo(() => {
    return {
      selectedTrade,
      setSelectedTrade,
      openAddNoteDialog: (item) => {
        item && setSelectedTrade(item)
        addNoteDialogRef.current?.showModal()
      },
      openTradeInfoDialog: (item) => {
        item && setSelectedTrade(item)
        tradeInfoDialogRef.current?.showModal()
      },
      openChoseJournalDialog: (hideJournalId) => {
        setHiddenJournalId(hideJournalId ?? [])
        return new Promise((resolve) => {
          selectJournalDialogPromiseResolveFm.current = resolve
          selectJournalDialogRef.current?.showModal()
        })
      },
      openConfirmDeleteTradeDialog: (item) => {
        setSelectedTrade(item)
        confirmDeleteRef.current?.showModal()
      },
      updateDashboard: onUpdateDashboard,
      updateJournals: onUpdateJournals,
    } satisfies TradesTableSectionContextData
  }, [selectedTrade, addNoteDialogRef, tradeInfoDialogRef, onUpdateDashboard, onUpdateJournals])

  const deleteTrade = async (tradeItem: TradeItem) => {
    setLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/trades/${tradeItem.id}`, 'DELETE')
      showNotification({ text: 'Trade deleted successfully', type: 'success' })
      confirmDeleteRef.current?.close()
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 406) {
          return showNotification({
            text: "Journal type doesn't support trades deletion",
            type: 'error',
          })
        } else if (err.status === 404) {
          return showNotification({ text: 'Trade does not exist', type: 'error' })
        } else {
          return showNotification({ text: 'Failed to delete trade', type: 'error' })
        }
      } else {
        console.error(err)
        showNotification({ text: 'Unknown error', type: 'error' })
      }
    } finally {
      setLoading(false)
      onUpdateDashboard()
      onUpdateJournals()
    }
  }

  return (
    <TradesTableSectionContext.Provider value={providerValue}>
      {children}
      <dialog ref={selectJournalDialogRef}>
        <SelectJournalDialog
          hiddenJournalId={hiddenJournalId}
          onClose={(journal) => {
            selectJournalDialogPromiseResolveFm.current(journal)
            selectJournalDialogRef.current?.close()
          }}
        />
      </dialog>
      <dialog ref={addNoteDialogRef}>
        <AddEditNoteDialog onClose={() => addNoteDialogRef.current?.close()} />
      </dialog>
      <dialog ref={tradeInfoDialogRef}>
        <TradeInfoDialog onClose={() => tradeInfoDialogRef.current?.close()} />
      </dialog>
      <dialog ref={confirmDeleteRef}>
        <ConfirmDeleteTradeDialog
          onClose={() => confirmDeleteRef.current?.close()}
          loading={loading}
          onDelete={() => {
            deleteTrade(selectedTrade!)
            return void 0
          }}
        />
      </dialog>
    </TradesTableSectionContext.Provider>
  )
}

import { API_URL } from 'core/constants'
import { useApi } from 'core/hooks/api/useApi'
import { JournalDetailedInfo, JournalInfo, JournalType, SWRResp } from 'core/types'
import { appFetch } from 'core/utils'
import { AddJournalDialog } from 'pages/DashboardPage/dialogs/AddJournalDialog'
import { ConfirmDeleteDialog } from 'pages/DashboardPage/dialogs/ConfirmDeleteDialog'
import { EditJournalDialog } from 'pages/DashboardPage/dialogs/EditJournalDialog'
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useNotifications } from 'shared/Notifications'

type UpdateJournalsResponse = Promise<{
  journals?: { result: JournalInfo[] }
  journalsDetailed?: { result: JournalDetailedInfo[] }
  journalTypes?: { result: JournalType[] }
}>

const initialSwrData = {
  data: undefined,
  error: undefined,
  isValidating: false,
  mutate: () => Promise.resolve() as Promise<undefined>,
  isLoading: true,
}

type JournalsApiContextData = {
  journals: SWRResp<{ result: JournalInfo[] }>
  journalsDetailed: SWRResp<{ result: JournalDetailedInfo[] }>
  journalTypes: SWRResp<{ result: JournalType[] }>
  updateJournals: () => UpdateJournalsResponse
  openAddDialog: () => void
  openEditDialog: (item: JournalInfo | JournalDetailedInfo) => void
  openDeleteDialog: (item: JournalInfo | JournalDetailedInfo) => void
}
export const JournalsApiContext = createContext<JournalsApiContextData>({
  journals: initialSwrData,
  journalsDetailed: initialSwrData,
  journalTypes: initialSwrData,
  updateJournals: () => {
    throw new Error('Not implemented')
  },
  openAddDialog: () => {
    throw new Error('Not implemented')
  },
  openEditDialog: () => {
    throw new Error('Not implemented')
  },
  openDeleteDialog: () => {
    throw new Error('Not implemented')
  },
})
export const useJournalsApi = () => useContext(JournalsApiContext)

export function JournalsApiContextProvider({ children }: any) {
  const journals = useApi<{ result: JournalInfo[] }>('/api/dashboard/journals', {
    refreshInterval: 1000 * 60 * 5,
  })
  const journalsDetailed = useApi<{ result: JournalDetailedInfo[] }>(
    '/api/dashboard/journals/detailed',
    { refreshInterval: 1000 * 60 * 5 }
  )
  const journalTypes = useApi<{ result: JournalType[] }>('/api/dashboard/journals/types', {
    refreshInterval: 1000 * 60 * 20,
  })

  const updateJournals: () => UpdateJournalsResponse = useCallback(async () => {
    const result = await Promise.all([
      journals.mutate(),
      journalsDetailed.mutate(),
      journalTypes.mutate(),
    ])
    return { journals: result[0], journalsDetailed: result[1], journalTypes: result[2] }
  }, [journalTypes, journals, journalsDetailed])
  const { showNotification } = useNotifications()

  const addDialog = useRef<HTMLDialogElement>(null)
  const editJournalDialogRef = useRef<HTMLDialogElement>(null)
  const confirmDeleteDialogRef = useRef<HTMLDialogElement>(null)

  // Current journal "delete" & "update" modals work with. Has nothing to do with filters
  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | JournalDetailedInfo | null>(
    null
  )
  const [deleteLoading, setDeleteLoading] = useState(false)
  const deleteJournal = async () => {
    if (!selectedJournal) return
    setDeleteLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/journals/${selectedJournal.id}`, 'DELETE')
      showNotification({
        text: `Journal "${selectedJournal.journalName}" was removed`,
        type: 'success',
      })
      updateJournals()
    } catch (err) {
      console.error(err)
      showNotification({ text: 'Failed to delete journal', type: 'error' })
    } finally {
      setDeleteLoading(false)
      confirmDeleteDialogRef.current?.close()
    }
  }

  const providerValue = useMemo(
    () =>
      ({
        journals,
        journalTypes,
        journalsDetailed,
        updateJournals,
        openAddDialog: () => addDialog.current?.showModal(),
        openEditDialog: (item: JournalInfo | JournalDetailedInfo) => {
          setSelectedJournal(item)
          editJournalDialogRef.current?.showModal()
        },
        openDeleteDialog: (item: JournalInfo | JournalDetailedInfo) => {
          setSelectedJournal(item)
          confirmDeleteDialogRef.current?.showModal()
        },
      } satisfies JournalsApiContextData),
    [journals, journalTypes, updateJournals, journalsDetailed]
  )

  return (
    <JournalsApiContext.Provider value={providerValue}>
      <dialog ref={addDialog}>
        <AddJournalDialog onClose={() => addDialog.current?.close()} />
      </dialog>
      <dialog ref={editJournalDialogRef}>
        <EditJournalDialog
          journal={selectedJournal}
          onClose={() => editJournalDialogRef.current?.close()}
        ></EditJournalDialog>
      </dialog>
      <dialog ref={confirmDeleteDialogRef}>
        <ConfirmDeleteDialog
          loading={deleteLoading}
          onClose={() => confirmDeleteDialogRef.current?.close()}
          onDelete={() => deleteJournal()}
        />
      </dialog>
      {children}
    </JournalsApiContext.Provider>
  )
}

export function getDatesToPick(relativeTimezoneOffsetMs: number, baseDate: Date): Date[] {
  const dates: Date[] = []

  const getInitialDate = () => {
    let initialDate = new Date(baseDate.getTime())
    initialDate.setHours(0, 0, 0, 0)
    initialDate = new Date(initialDate.getTime() - relativeTimezoneOffsetMs)
    return initialDate
  }

  const initial = getInitialDate()

  for (let i = 0; i < 24 * 4; i++) {
    dates.push(new Date(initial.getTime() + i * 15 * 60 * 1000))
  }

  return dates
}

export function compareTime(a: Date, b: Date): boolean {
  return a.getMinutes() === b.getMinutes() && a.getHours() === b.getHours()
}

import { NotificationItem, NotificationType } from './types'

import imgSuccess from 'assets/icons/notification/success.svg'
import imgWarning from 'assets/icons/notification/warning.svg'
import imgError from 'assets/icons/notification/error.svg'
import imgInfo from 'assets/icons/notification/info.svg'
import imgQuestion from 'assets/icons/notification/question.svg'

const notificationTypeToIcon: Record<NotificationType, string> = {
  success: imgSuccess,
  warning: imgWarning,
  error: imgError,
  info: imgInfo,
  question: imgQuestion,
}

type NotificationProps = {
  item: NotificationItem
  onClose?: (id: NotificationItem['id']) => void
}
export function NotificationCard({ item, onClose }: Readonly<NotificationProps>): JSX.Element {
  const handleAction = () => {
    item.action && item.action()
    onClose && onClose(item.id)
  }

  return (
    <div className="NotificationCard">
      <div className="NotificationCard__icon">
        <img src={notificationTypeToIcon[item.type]} alt={item.type} width={24} height={24} />
      </div>
      <div className="NotificationCard__content">{item.text}</div>
      <div className="NotificationCard__action">
        <button className="link-button" onClick={handleAction}>
          {item.actionName ?? 'OK'}
        </button>
      </div>
      <button
        className="NotificationCard__close-button solid-button"
        onClick={() => onClose && onClose(item.id)}
      >
        🗙
      </button>
    </div>
  )
}

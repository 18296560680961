import { API_URL } from 'core/constants'
import { JournalInfo } from 'core/types'
import { HttpError, appFetch } from 'core/utils'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { EditJournalForm, EditJournalFormData } from './EditJournalForm'
import { useJournalsApi } from 'core/contexts/journalsApiContext'
import { useNotifications } from 'shared/Notifications'
import { useNavigate } from 'react-router-dom'

type AddJournalDialogProps = {
  onClose: () => void
}
export function AddJournalDialog({ onClose }: Readonly<AddJournalDialogProps>) {
  const { updateJournals, journals } = useJournalsApi()
  const journalsRef = useRef(journals)
  useEffect(() => {
    journalsRef.current = journals
  }, [journals])

  const [loading, setLoading] = useState(false)
  const [occupiedName, setOccupiedName] = useState<string | null>(null)

  const useFormReturn = useForm<EditJournalFormData>()
  const {
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
    formState: { isValid },
  } = useFormReturn

  const { showNotification } = useNotifications()
  const navigate = useNavigate()

  const close = () => {
    clearErrors()
    setValue('journalName', '')
    setValue('journalType', '')
    setOccupiedName(null)
    onClose()
  }

  const onSubmit = handleSubmit(async (data) => {
    if (!isValid) return
    setLoading(true)

    try {
      const response = await appFetch<{ result: JournalInfo }>(
        API_URL + '/api/dashboard/journals',
        'POST',
        {
          journalName: data.journalName,
          journalType: data.journalType,
        }
      )
      const { journals: updatedJournals } = await updateJournals()
      if (!updatedJournals) {
        console.error('Failed to update journals')
        return
      }

      showNotification({
        text: `Journal "${response.result.journalName}" added`,
        type: 'success',
        action: () => {
          const newJournal = updatedJournals.result.find((j) => j.id === response.result.id)
          if (!newJournal) return
          navigate('/dashboard?journal_id=' + newJournal.id, { replace: true })
          if (window.location.pathname === '/dashboard') window.location.reload()
        },
        actionName: 'Open',
      })
      close()
    } catch (error) {
      console.error('Failed to add journal\n', (error as any).body)
      if (error instanceof HttpError) {
        if (error.status === 400) {
          setOccupiedName(data.journalName)
        }
      }
      setTimeout(() => trigger())
    } finally {
      setLoading(false)
    }
  })

  return (
    <EditJournalForm
      loading={loading}
      occupiedName={occupiedName}
      useFormReturn={useFormReturn}
      onSubmit={onSubmit}
      onCancel={() => close()}
      actionText="Add"
    />
  )
}

import { useState } from 'react'
import { MultiselectDropdownItem } from './MultiselectDropdownItem'

type MultiselectDropdownItemProps<T> = {
  value: T[]
  allValues: T[]
  onChange: (value: T[]) => void
  onClose: () => void
  getId?: (value: T) => string
  getTextValue?: (value: T) => string
}
export function MultiselectDropdownItemList<T>({
  value,
  allValues,
  onChange,
  onClose,
  getId = (v) => String(v),
  getTextValue = (v) => String(v),
}: Readonly<MultiselectDropdownItemProps<T>>) {
  const [valueBuffer, setValueBuffer] = useState<T[]>(value)
  const [search, setSearch] = useState('')

  const handleValue = (isChecked: boolean, value: T) => {
    if (isChecked) {
      setValueBuffer([...valueBuffer, value])
    } else {
      setValueBuffer(valueBuffer.filter((item) => getId(item) !== getId(value)))
    }
  }

  const close = () => {
    setValueBuffer(value)
    onClose()
  }

  const apply = (value: T[]) => {
    onChange(value)
    close()
  }

  return (
    <div className="MultiselectDropdownItemList">
      <div className="search">
        <input
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name=""
          id=""
          placeholder="Search..."
        />
      </div>
      <div className="list">
        {allValues
          .filter((option) => {
            if (search === '') return true
            return getTextValue(option).toLowerCase().includes(search.toLowerCase())
          })
          .map((v) => (
            <MultiselectDropdownItem
              key={getId(v)}
              id={getId(v)}
              label={getTextValue(v)}
              value={valueBuffer.some((item) => getId(item) === getId(v))}
              onChange={(isChecked) => handleValue(isChecked, v)}
            />
          ))}
      </div>
      <div className="buttons">
        <button onClick={() => apply([])}>Clear all</button>
        <button className="accent-button" onClick={() => apply(valueBuffer)}>
          Apply
        </button>
      </div>
    </div>
  )
}

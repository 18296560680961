import { FormEvent, useState } from 'react'
import './Login.scss'
import logo from 'assets/trading-journal-logo.svg'
import { FlatCard } from 'shared/FlatCard/FlatCard'
import { LabeledDivider } from 'shared/LabeledDivider/LabeledDivider'
import { useNavigate } from 'react-router-dom'
import { API_URL, LS_RESTORE_PATH } from 'core/constants'
import { HttpError, appFetch } from 'core/utils'

export function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const submit = async (event: FormEvent) => {
    event.preventDefault()
    if (!username) return
    if (!password) return
    setLoading(true)

    const handleError = (err: unknown): string => {
      if (err instanceof HttpError) {
        if (err.status === 401) return 'Wrong username or password'
        if (err.message && err.message !== '') return err.message
      }
      return 'Unknown error'
    }

    try {
      const response = await appFetch(API_URL + '/api/dashboard/login', 'POST', {
        username,
        password,
      })
      setError('')
      if ('access_token' in response) {
        localStorage.setItem('TJ_ACCESS_TOKEN', response.access_token)
        const restorePath = localStorage.getItem(LS_RESTORE_PATH)
        navigate(restorePath ?? '/')
        localStorage.removeItem(LS_RESTORE_PATH)
        window.location.reload()
      } else {
        throw new Error('No access token in response!')
      }
    } catch (err) {
      setError(handleError(err))
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="Login card-page">
      <FlatCard>
        <img src={logo} alt="Logo" />
        <form onSubmit={(e) => submit(e)}>
          <div className="form-control">
            <label htmlFor="login">Email</label>
            <input
              type="text"
              id="login"
              placeholder="Enter email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <a href="https://bookmap.com/support/" target="_blank" rel="noreferrer">
              Forgot password?
            </a>
          </div>

          {error !== '' ? <div className="error">{error}</div> : null}

          <button
            className="accent-button"
            type="submit"
            disabled={username === '' || password === ''}
          >
            {loading ? 'Loading...' : 'Log in'}
          </button>
        </form>

        <div className="alternative-section">
          <LabeledDivider label="Don't have an account?" />
          <button className="solid-button" onClick={() => navigate('/signup')}>
            Register with Bookmap credentials
          </button>
        </div>
      </FlatCard>
    </div>
  )
}

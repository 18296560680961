export type ItemProps<T> = {
  value: T
  selected?: boolean
  onSelect?: (value: T) => void
  children: React.ReactNode
}
export function DropdownItem<T>({ value, selected, onSelect, children }: Readonly<ItemProps<T>>) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onSelect && onSelect(value)
    event.stopPropagation()
  }

  return (
    <button
      className={'DropdownItem menu-button ' + (selected ? 'DropdownItem--selected' : '')}
      onClick={(event) => handleClick(event)}
    >
      {children}
    </button>
  )
}

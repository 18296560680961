import { useMemo, useState } from 'react'
import Floating from 'shared/Floating'
import { DropdownTrigger } from '../components/DropdownTrigger'
import { MultiselectDropdownItemList } from './components/MultiselectDropdownItemList'
import './MultiselectDropdown.scss'
import classNames from 'classnames'

type MultiselectDropdownProps<T> = {
  value: T[]
  allValues: T[]
  placeholder?: string
  className?: string
  onChange: (value: T[]) => void
  getVisualValue?: (value: T) => string
}
export function MultiselectDropdown<T>({
  value,
  allValues,
  onChange,
  className,
  getVisualValue = (v) => String(v),
  placeholder = 'Select an option',
}: Readonly<MultiselectDropdownProps<T>>) {
  const [opened, setOpened] = useState(false)

  const joinedValue = useMemo(() => value.map(getVisualValue).join(','), [value, getVisualValue])

  return (
    <div className={classNames('MultiselectDropdown', className)}>
      <Floating
        opened={opened}
        setOpened={setOpened}
        closeOnClickInside={false}
        trigger={
          <DropdownTrigger
            opened={opened}
            placeholder={placeholder}
            value={value.length > 0 ? joinedValue : undefined}
          />
        }
        content={
          <MultiselectDropdownItemList
            value={value}
            allValues={allValues}
            onChange={onChange}
            onClose={() => setOpened(false)}
          />
        }
      />
    </div>
  )
}

export default MultiselectDropdown

import classNames from 'classnames'
import './TextMoney.scss'
import { formatMoney } from 'core/utils'

type TextMoneyProps = { value: number; colored?: boolean }
function TextMoney({ value, colored }: Readonly<TextMoneyProps>) {
  return (
    <span
      className={classNames(
        'TextMoney',
        colored && value > 0 ? 'positive' : '',
        colored && value < 0 ? 'negative' : ''
      )}
    >
      {formatMoney(value)}
    </span>
  )
}

export default TextMoney

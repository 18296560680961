export type DropdownActionItemProps = {
  children: React.ReactNode
  onClick?: () => void
  onCloseMenu?: () => void
}
export function DropdownActionItem({
  children,
  onClick,
  onCloseMenu,
}: Readonly<DropdownActionItemProps>) {
  return (
    <button
      className="DropdownActionItem menu-button text-normal-regular"
      onClick={() => {
        onClick && onClick()
        onCloseMenu && onCloseMenu()
      }}
    >
      {children}
    </button>
  )
}

import { AllHTMLAttributes, useState } from 'react'
import Floating from 'shared/Floating'

import './Menu.scss'

type MenuProps = {
  trigger: React.ReactNode
  children: React.ReactNode
}
function Menu({ trigger, children }: Readonly<MenuProps>) {
  const [opened, setOpened] = useState(false)

  return (
    <Floating
      opened={opened}
      setOpened={setOpened}
      content={
        <div className="Menu" onClick={() => setOpened(false)}>
          {children}
        </div>
      }
      trigger={trigger}
    />
  )
}

function MenuItem({
  children,
  onClick,
}: Readonly<{ children: React.ReactNode } & AllHTMLAttributes<HTMLButtonElement>>) {
  return (
    <button className="MenuButton menu-button" onClick={onClick}>
      {children}
    </button>
  )
}

function MenuDivider() {
  return <div className="MenuDivider menu-separator" />
}

type MenuSubmenuProps = {
  children: React.ReactNode
  label: React.ReactNode
  trigger: React.ReactNode
}
function MenuSubmenu({ children, label, trigger }: Readonly<MenuSubmenuProps>) {
  const [opened, setOpened] = useState(false)

  return (
    <Floating
      opened={opened}
      setOpened={setOpened}
      content={<div className="Menu">{children}</div>}
      trigger={<div onClick={(e) => e.stopPropagation()}>{trigger}</div>}
    />
  )
}

Menu.Item = MenuItem
Menu.Submenu = MenuSubmenu
Menu.Divider = MenuDivider
export default Menu

import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { useContext, useEffect, useState } from 'react'
import './Filters.scss'
import { DateInput } from 'shared/DateInput/DateInput'

import imgPlus from 'assets/icons/plus.svg'
import imgSettings from 'assets/icons/settings.svg'
import SimpleDropdown from 'shared/Dropdown/SimpleDropdown/SimpleDropdown'
import { useNavigate } from 'react-router-dom'
import { AliasesGetResponse, JournalInfo } from 'core/types'
import MultiselectDropdown from 'shared/Dropdown/MultiselectDropdown/MultiselectDropdown'
import { useApi } from 'core/hooks/api/useApi'
import { useJournalsApi } from 'core/contexts/journalsApiContext'
import classNames from 'classnames'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'

const useScrolling = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => setIsScrolled(document.documentElement.scrollTop > 90)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isScrolled }
}

/** Section with date & time filters */
export function Filters() {
  const { filters, setFilters } = useContext(FilterContext)
  const { journals, openAddDialog } = useJournalsApi()
  const aliasesAll = useApi<AliasesGetResponse>('/api/dashboard/aliases')

  const navigate = useNavigate()

  const applyJournal = (journal: JournalInfo | null) => {
    if (journal === null) {
      setFilters((filters) => ({ ...filters, journal: null }))
      return
    }
    if (journal.id === filters.journal?.id) return
    setFilters((filters) => ({ ...filters, journal }))
  }

  const { isScrolled } = useScrolling()
  const { dateFormatter } = useGlobalTimezone()

  return (
    <div className={classNames('Filters', isScrolled && 'scrolled')}>
      <div className="filters">
        <div className="filters__content">
          <SimpleDropdown
            className="filter"
            value={filters.journal}
            onChange={(journal) => applyJournal(journal)}
            getVisualValue={(journal) => (journal ? journal.journalName : 'All journals')}
          >
            <SimpleDropdown.Item value={null}>
              <SimpleDropdown.ItemText
                primary="All journals"
                secondary={(journals.data?.result.length ?? 1) + ' journals combined'}
              />
            </SimpleDropdown.Item>
            {journals.data
              ? journals.data.result.map((journal) => (
                  <SimpleDropdown.Item value={journal} key={journal.id}>
                    <SimpleDropdown.ItemText
                      primary={journal.journalName}
                      secondary={
                        journal.updateDate
                          ? 'Updated on ' + dateFormatter.format(new Date(journal.updateDate))
                          : '-'
                      }
                    />
                  </SimpleDropdown.Item>
                ))
              : null}
            <SimpleDropdown.Divider />
            <SimpleDropdown.ActionItem onClick={() => openAddDialog()}>
              <img src={imgPlus} alt="Add" /> Add journal
            </SimpleDropdown.ActionItem>
          </SimpleDropdown>
          <MultiselectDropdown
            className="filter"
            value={filters.symbols}
            allValues={aliasesAll.data?.result ?? []}
            placeholder="All Instruments"
            onChange={(sy) => setFilters((v) => ({ ...v, symbols: sy }))}
          ></MultiselectDropdown>

          <div className="label">from</div>
          <DateInput
            className="filter"
            value={filters.dateFrom}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateFrom: date }))}
          />
          <div className="label">to</div>
          <DateInput
            className="filter"
            value={filters.dateTo}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateTo: date }))}
          />
        </div>
      </div>

      <div className="control-buttons">
        <button className="solid-button panel-button" onClick={() => openAddDialog()}>
          <img src={imgPlus} alt="Create" /> <span className="medium">Add</span>{' '}
          <span className="big">new journal</span>
        </button>
        <button className="panel-button" onClick={() => navigate('/manage-journals')}>
          <img src={imgSettings} alt="Manage" /> <span className="medium">Manage</span>{' '}
          <span className="big">journals</span>
        </button>
      </div>
    </div>
  )
}

import { useJournalsApi } from 'core/contexts/journalsApiContext'
import { JournalInfo } from 'core/types'
import './SelectJournalDialog.scss'
import { SimpleDropdown } from 'shared/Dropdown'
import { DropdownItem } from 'shared/Dropdown/components/DropdownItem'
import { useState } from 'react'

type SelectJournalDialogProps = {
  onClose: (journal?: JournalInfo) => void
  hiddenJournalId: string[]
}
export function SelectJournalDialog({
  onClose,
  hiddenJournalId,
}: Readonly<SelectJournalDialogProps>) {
  const { journals } = useJournalsApi()
  const [value, setValue] = useState<JournalInfo | undefined>(undefined)

  const journalsFiltered =
    journals.data?.result.filter((j) => !hiddenJournalId.includes(j.id)) ?? []

  return (
    <div className="SelectJournalDialog modal">
      <div className="modal-header">Move trade to..</div>

      <form>
        <div className="form-control">
          <label>Target journal</label>
          <SimpleDropdown
            className="input"
            value={value}
            getVisualValue={(j) => j.journalName}
            onChange={(v) => setValue(v)}
            placeholder="Select journal"
          >
            {journalsFiltered.map((journal) => (
              <DropdownItem value={journal} key={journal.id}>
                {journal.journalName}
              </DropdownItem>
            ))}
          </SimpleDropdown>
        </div>
      </form>

      <div className="modal-buttons">
        <button
          className="solid-button"
          onClick={() => {
            setValue(void 0)
            onClose()
          }}
        >
          Cancel
        </button>
        <button disabled={!value} className="accent-button" onClick={() => value && onClose(value)}>
          Move
        </button>
      </div>
    </div>
  )
}

export async function fetcher([input, token]: [RequestInfo | URL, string | undefined]) {
  const response = token
    ? await fetch(input, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : await fetch(input)

  if (response.status === 401) {
    localStorage.removeItem('TJ_ACCESS_TOKEN')
    if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
      window.location.reload()
    }
  }

  if (!response.ok) {
    throw await response.json()
  }

  return response.json()
}

import { Controller, UseFormReturn } from 'react-hook-form'
import { SimpleDropdown } from 'shared/Dropdown'
import classNames from 'classnames'
import { useJournalsApi } from 'core/contexts/journalsApiContext'
import { JOURNALS_TYPE_NAME_MAP } from 'core/journalsNameMap'

export type EditJournalFormData = {
  journalName: string
  journalType: string
}

type EditJournalFormProps = {
  useFormReturn: UseFormReturn<EditJournalFormData, any, undefined>
  occupiedName: string | null
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  onCancel: () => void
  loading: boolean
  actionText: string
  disableTypeDropdown?: boolean
}
export function EditJournalForm({
  useFormReturn: {
    control,
    register,
    formState: { errors, isValid, isSubmitted },
  },
  onSubmit,
  onCancel,
  occupiedName,
  loading,
  actionText,
  disableTypeDropdown,
}: Readonly<EditJournalFormProps>) {
  const { journalTypes } = useJournalsApi()

  return (
    <div className="AddJournalDialog modal">
      <form onSubmit={onSubmit}>
        <h4 className="modal-header">{actionText} journal</h4>

        <div className="modal-form">
          <div className="modal-form-control">
            <label className="modal-form-control__label" htmlFor="journal-name">
              Journal name
            </label>
            <input
              {...register('journalName', {
                required: 'Journal name is required',
                validate: (v) => v !== occupiedName || 'Journal name is already taken',
              })}
              id="journal-name"
              type="text"
              className={classNames({
                'modal-form-control__input': true,
                error: errors.journalName,
              })}
              placeholder="Journal name"
            />
            {errors.journalName && (
              <div className="modal-form-control__error">{errors.journalName.message}</div>
            )}
          </div>
          <div className="modal-form-control">
            <label className="modal-form-control__label" htmlFor="journal-type">
              Journal type
            </label>
            {journalTypes.isLoading ? (
              <SimpleDropdown
                className="modal-form-control__input"
                disabled
                placeholder="Loading..."
              ></SimpleDropdown>
            ) : (
              <Controller
                control={control}
                name="journalType"
                rules={{ required: 'Journal type is required' }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SimpleDropdown
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    id="journal-type"
                    className={classNames({
                      'modal-form-control__input': true,
                      error: errors.journalType,
                    })}
                    getVisualValue={(v) => JOURNALS_TYPE_NAME_MAP[v] ?? v}
                    placeholder="Journal type"
                    disabled={disableTypeDropdown}
                  >
                    {journalTypes.data?.result
                      .filter((t) => t.canBeCreatedByUser)
                      .map((jType) => (
                        <SimpleDropdown.Item value={jType.typeName} key={jType.typeName}>
                          {JOURNALS_TYPE_NAME_MAP[jType.typeName] ?? jType.typeName}
                        </SimpleDropdown.Item>
                      ))}
                  </SimpleDropdown>
                )}
              />
            )}
            {errors.journalType && (
              <div className="modal-form-control__error">{errors.journalType.message}</div>
            )}
          </div>
        </div>

        <div className="modal-buttons">
          <button className="solid-button" type="button" onClick={() => onCancel()}>
            Cancel
          </button>
          {loading ? (
            <button className="accent-button" disabled>
              Loading...
            </button>
          ) : (
            <button type="submit" className="accent-button" disabled={!isValid && isSubmitted}>
              {actionText}
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

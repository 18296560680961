import { useContext, useEffect, useState } from 'react'
import { appFetch } from 'core/utils'
import { API_URL } from 'core/constants'
import { TradesTableSectionContext } from '../TradesTableSectionContext'

type AddEditNoteDialogProps = {
  onClose: () => void
}

function AddEditNoteDialog({ onClose }: Readonly<AddEditNoteDialogProps>) {
  const { selectedTrade, updateDashboard } = useContext(TradesTableSectionContext)
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState<'Add' | 'Update'>('Add')

  const updateTradeNote = async (note: string) => {
    if (!selectedTrade) return
    setLoading(true)

    const requestParams = selectedTrade.tradeNote
      ? { url: `/api/dashboard/trades/note/${selectedTrade.tradeNote.id}`, method: 'PUT' }
      : { url: `/api/dashboard/trades/${selectedTrade.id}/note`, method: 'POST' }

    try {
      await appFetch(API_URL + requestParams.url, requestParams.method, { noteText: note })
      onClose()
      updateDashboard()
    } catch (error) {
      alert('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const handleAddNote = () => {
    updateTradeNote(note)
    setNote('')
  }

  const handleCancel = () => {
    onClose()
  }

  useEffect(() => {
    if (selectedTrade === null) return
    setNote(selectedTrade.tradeNote?.noteText ?? '')
    const newAction =
      selectedTrade.tradeNote && selectedTrade.tradeNote.noteText !== '' ? 'Update' : 'Add'
    if (newAction !== action) setAction(newAction)
  }, [selectedTrade, action])

  return (
    <div className="AddNoteDialog modal">
      <div className="modal-header">Add note</div>
      <form action="">
        <div className="form-control">
          <label htmlFor="note-text">Note</label>
          <textarea
            tabIndex={0}
            id="note-text"
            value={note}
            style={{ height: '64px' }}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </form>
      <div className="modal-buttons">
        <button className="solid-button" disabled={loading} onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="accent-button"
          disabled={
            loading ||
            (selectedTrade?.tradeNote?.noteText === note &&
              selectedTrade?.tradeNote?.noteText !== '')
          }
          onClick={handleAddNote}
        >
          {loading ? 'Loading...' : action}
        </button>
      </div>
    </div>
  )
}

export default AddEditNoteDialog

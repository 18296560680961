import { API_URL, TABLE_PAGE_SIZE } from 'core/constants'
import { JournalInfo, TradeItem, TradesResponse } from 'core/types'
import './TradesTable.scss'
import img3DotsVertical from 'assets/icons/dots-vertical.svg'

import Table from 'shared/Table'
import TextDate from 'shared/TextDate/TextDate'
import TextMoney from 'shared/TextMoney/TextMoney'
import { useContext, useMemo } from 'react'
import { TradesTableSectionContext } from '../TradesTableSectionContext'
import Chip from 'shared/Chip'
import TableCell from 'shared/Table/components/TableCell'
import Menu from 'shared/Menu'
import { appFetch } from 'core/utils'
import { useNotifications } from 'shared/Notifications'
import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { useJournalsApi } from 'core/contexts/journalsApiContext'

type TradesTableProps = { data: TradesResponse }
export function TradesTable({ data }: Readonly<TradesTableProps>) {
  const {
    openAddNoteDialog,
    openTradeInfoDialog,
    openChoseJournalDialog,
    openConfirmDeleteTradeDialog,
    updateDashboard,
    updateJournals,
  } = useContext(TradesTableSectionContext)

  const { showNotification } = useNotifications()
  const { filters } = useContext(FilterContext)
  const { journals } = useJournalsApi()
  const journalsIdMap: Record<string, JournalInfo> = useMemo(() => {
    if (!journals.data) return {}
    return Object.fromEntries(journals.data.result.map((j) => [j.id, j]))
  }, [journals])

  const isEmpty = data?.pageInfo.totalElements === 0

  const openChoseDialog = async (tradeItem: TradeItem) => {
    const journal = await openChoseJournalDialog([tradeItem.journalId])
    console.log(journal)
    if (!journal) return
    try {
      await appFetch(API_URL + `/api/dashboard/trades/moveTo/${journal.id}`, 'PUT', [tradeItem.id])
      showNotification({
        text: 'Trade moved successfully to ' + journal.journalName,
        type: 'success',
      })
      updateDashboard()
      updateJournals()
    } catch (err) {
      console.error(err)
      showNotification({ text: 'Failed to move trade', type: 'error' })
    }
  }

  return (
    <Table className="TradesTable" tableNeighbor={isEmpty && <EmptyState />}>
      <Table.Header>
        {filters.journal === null && <Table.HeaderCell>Journal</Table.HeaderCell>}
        <Table.HeaderCell>Entry date</Table.HeaderCell>
        <Table.HeaderCell>Exit date</Table.HeaderCell>
        <Table.HeaderCell>Instrument</Table.HeaderCell>
        <Table.HeaderCell>Max size</Table.HeaderCell>
        <Table.HeaderCell>Entry price</Table.HeaderCell>
        <Table.HeaderCell>Exit price</Table.HeaderCell>
        <Table.HeaderCell>Profit</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell tooltipText="Maximum Favorable Excursion">MFE</Table.HeaderCell>
        <Table.HeaderCell tooltipText="Maximum Adverse Excursion">MAE</Table.HeaderCell>
        <Table.HeaderCell>Note</Table.HeaderCell>
        <Table.HeaderCell.Empty />
      </Table.Header>
      <Table.Body>
        {!isEmpty &&
          data.result.map((item) => (
            <Table.Row key={item.id} onClick={() => openTradeInfoDialog(item)}>
              {filters.journal === null && (
                <Table.Cell className="no-wrap">
                  {journalsIdMap[item.journalId].journalName ?? '-'}
                </Table.Cell>
              )}

              <Table.Cell>
                {item.timeEntry ? <TextDate date={new Date(item.timeEntry)} /> : '-'}
              </Table.Cell>
              <Table.Cell>
                {item.timeExit ? <TextDate date={new Date(item.timeExit)} /> : '-'}
              </Table.Cell>
              <Table.Cell className="bold">{item.instrumentAlias}</Table.Cell>
              <Table.Cell className="bold">{item.maxSize}</Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.priceEntry} />
              </Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.priceExit} />
              </Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.profit} colored />
              </Table.Cell>
              <Table.Cell>
                {item.isBuy ? (
                  <Chip className="bg-green">Buy</Chip>
                ) : (
                  <Chip className="bg-red">Sell</Chip>
                )}
              </Table.Cell>
              <Table.Cell className="bold">{item.mfe}</Table.Cell>
              <Table.Cell className="bold">{item.mae}</Table.Cell>
              <NoteCell noteItem={item.tradeNote} />
              <TableCell.Menu>
                <div className="center">
                  <Menu
                    trigger={
                      <button className="trade-menu-button text-button">
                        <img src={img3DotsVertical} alt="Edit" />
                      </button>
                    }
                  >
                    {item.tradeNote === null || item.tradeNote.noteText === '' ? (
                      <Menu.Item onClick={() => openAddNoteDialog(item)}>Add note</Menu.Item>
                    ) : (
                      <Menu.Item onClick={() => openAddNoteDialog(item)}>Edit note</Menu.Item>
                    )}
                    {/* <Menu.Item>Clear note</Menu.Item> */}
                    {item.isLocked === false && (
                      <>
                        <Menu.Divider />
                        <Menu.Item onClick={() => openChoseDialog(item)}>Move to...</Menu.Item>
                        <Menu.Item
                          className="red"
                          onClick={() => openConfirmDeleteTradeDialog(item)}
                        >
                          Delete trade
                        </Menu.Item>
                      </>
                    )}
                  </Menu>
                </div>
              </TableCell.Menu>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}

type NoteCellProps = { noteItem: TradeItem['tradeNote']; onOpenAddNoteDialog?: () => void }
function NoteCell({ noteItem, onOpenAddNoteDialog }: Readonly<NoteCellProps>) {
  return (
    <Table.Cell className="NoteCell">
      {noteItem === null || noteItem.noteText === '' ? (
        <div className="text empty"> - </div>
      ) : (
        <abbr title={noteItem.noteText} className="text">
          {noteItem.noteText}
        </abbr>
      )}
      {/* <button className="text-button" onClick={() => onOpenAddNoteDialog()}>
        {noteItem === null || noteItem.noteText === '' ? (
          <div className="text empty">Add note...</div>
        ) : (
          <abbr title={noteItem.noteText} className="text">
            {noteItem.noteText}
          </abbr>
        )}
        <img src={imgPencil} alt="Edit" />
      </button> */}
    </Table.Cell>
  )
}

function EmptyState() {
  return (
    <div className="EmptyState" style={{ height: 48 * TABLE_PAGE_SIZE + 'px' }}>
      <h2>No trades</h2>
      <p>
        It looks like there are no results that match your search criteria. Please, try another one
      </p>
    </div>
  )
}

import './SignUp.scss'
import { FlatCard } from 'shared/FlatCard/FlatCard'
import logo from 'assets/trading-journal-logo.svg'
import { LabeledDivider } from 'shared/LabeledDivider/LabeledDivider'
import { useNavigate } from 'react-router-dom'
import { SignUpForm } from './SignUpForm'

export function SignUp() {
  const navigate = useNavigate()

  return (
    <div className="SignUp card-page">
      <FlatCard>
        <img src={logo} alt="Logo" />

        <SignUpForm />

        <div className="alternative-section">
          <LabeledDivider label="Have an account?" />
          <button className="solid-button" onClick={() => navigate('/login')}>
            Login
          </button>
        </div>
      </FlatCard>
    </div>
  )
}
